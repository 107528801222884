import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthcontexts } from './Authcontexts';
import axios from 'axios';
import { URL } from '../Constants';

const FetchCashBook = createContext();
export default function FetchCashBookProvider({ children }) {

    const [getCashBook, setGetCashBook] = useState([]);
    const { user } = useAuthcontexts();


    const showCashBook = async () => {


        const { shopId } = user;

        if (shopId) {

            axios.post(`${URL}/cashBook/get`, { shopId: shopId })
                .then((res) => {
                    const { data } = res
                    setGetCashBook(data)
                }).catch((err) => {
                    console.log('err', err)
                });

        } else {
            console.error("User UID is undefined.");
        }


    }
    useEffect(() => {
        if (user) {
            showCashBook();
        }
    }, [user]);


    return (
        <FetchCashBook.Provider value={{ getCashBook, setGetCashBook, showCashBook }}>
            {children}
        </FetchCashBook.Provider>
    );
}

export const useFetchCashBook = () => useContext(FetchCashBook);
