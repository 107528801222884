import React from 'react';
import './sidebar.scss';
import { Link, useLocation } from 'react-router-dom';

import { AiOutlineHome } from 'react-icons/ai';
import { BsPencilSquare, BsCashCoin } from 'react-icons/bs';
import { LuBookMinus } from 'react-icons/lu';
import { MdAdminPanelSettings } from "react-icons/md";
import { FiLogOut } from 'react-icons/fi';
import { useAuthcontexts } from '../../contexts/Authcontexts';

export default function Sidebar() {
    const { user, setIsAuthenticated } = useAuthcontexts();
    const location = useLocation(); // To get the current route

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        setIsAuthenticated(false);
    };

    const tabs = [
        { path: '/', icon: <AiOutlineHome size={20} />, label: 'Home' },
        { path: '/accounts', icon: <BsPencilSquare size={20} />, label: 'Accounts' },
        { path: '/cashbook', icon: <BsCashCoin size={20} />, label: 'Cash Book' },
        { path: '/ledger', icon: <LuBookMinus size={20} />, label: 'Ledger' }
    ];

    return (
        <>
            <div className="container">
                <div className="row px-0">
                    <div className="col px-0">
                        <div className='d-flex justify-content-between flex-column' style={{ height: "83vh" }}>
                            <div>
                                <ul className="nav nav-pills flex-column mt-2 mt-sm-3 ms-0 ms-md-3">
                                    {tabs.map((tab, index) => (
                                        <li key={index} className="nav-item text-dark mb-3 me-3">
                                            <Link
                                                to={tab.path}
                                                className={`nav-link text-dark text-decoration-none py-1`} style={{ background: location.pathname === tab.path ? '#b6e3c6' : '' }}>
                                                {tab.icon}
                                                <span className='ms-2 d-none d-sm-inline'>{tab.label}</span>
                                            </Link>
                                        </li>
                                    ))}

                                    <div className="dropdown open ms-1 mb-3">
                                        <a href='a' className="btn dropdown-toggle text-white" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fa-solid fa-square-poll-vertical fs-5"></i>
                                            <span className='d-none d-sm-inline ms-2'>Reports</span>
                                            <i className="fa-solid fa-caret-down ms-2"></i>
                                        </a>
                                        <div className="dropdown-menu">
                                            <Link to="payable" className="dropdown-item">PayAble</Link>
                                            <Link to="recieveable" className="dropdown-item">RecieveAble</Link>
                                            <Link to="pay-receiveable" className="dropdown-item">pay/RecieveAble</Link>
                                            <Link to="trial-balance" className="dropdown-item">Trail-Balance</Link>
                                        </div>
                                    </div>

                                    {user?.roles !== "accountant" && (
                                        <div className="dropdown open ms-1 mb-3">
                                            <a href='a' className="btn dropdown-toggle text-white" type="button" id="triggerId" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <MdAdminPanelSettings size={20} style={{ color: "#005a16" }} />
                                                <span className='d-none d-sm-inline ms-2'>Managers</span>
                                                <i className="fa-solid fa-caret-down ms-2"></i>
                                            </a>
                                            <div className="dropdown-menu">
                                                {user?.roles === "SuperAdmin" ? (
                                                    <Link to="register-admin" className="dropdown-item">Add Admin</Link>
                                                ) : user?.roles === "admin" ? (
                                                    <Link to="register-accountant" className="dropdown-item">Add Accountant</Link>
                                                ) : null}
                                            </div>
                                        </div>
                                    )}
                                </ul>
                            </div>
                            <div className='text-center mb-3'>
                                <button className='btn btn-success rounded w-50 px-0 px-md-3' onClick={handleLogout} style={{ background: "#1b4332" }}>
                                    <Link to="/auth/login" className='text-decoration-none text-white'>
                                        <FiLogOut size={20} />
                                        <span className='d-none d-sm-inline'>Logout</span>
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
