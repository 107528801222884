import React from 'react'
import './profile.scss';
import Avatar from 'react-avatar';



export default function Profile() {
    return (
        <>

            <section className="">
                <div className="container-fluid py-0 py-md-4 h-100">
                    <h4 className='mt-0'>Profile</h4>
                    <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12 col-md-8 col-lg-6 col-xl-5">
                            <div style={{ borderRadius: '1rem' }}>
                                <div className="card-body p-0 p-md-3 bg-white">

                                    <div className="mb-md-5 mt-md-4 pb-5">

                                        <div className='text-center py-2'>
                                            <Avatar src={require("../../../assests/images/man.png")} />
                                        </div>
                                        <form action="">
                                            <div className="row mx-2 mb-3">
                                                <div className="col">
                                                    <label htmlFor="" style={{ color: "#156829" }}>Name</label>
                                                    <input type="text" placeholder='Zaman Ali' className='form-control form-control-sm ' />
                                                </div>
                                            </div>
                                            <div className="row mx-2 mb-3">
                                                <div className="col">
                                                    <label htmlFor="" style={{ color: "#156829" }}>Email</label>
                                                    <input type="email" placeholder='Sweetzamanali@gmail.com' className='form-control form-control-sm ' />
                                                </div>
                                            </div>
                                            <div className="row mx-2 mb-3">
                                                <div className="col">
                                                    <label htmlFor="" style={{ color: "#156829" }}>Phone Number</label>
                                                    <input type="number" placeholder='+92 306 6525299' className='form-control form-control-sm ' />
                                                </div>
                                            </div>
                                            <div className="row mx-2 mb-3">
                                                <div className="col">
                                                    <label htmlFor="" style={{ color: "#156829" }}>Adress</label>
                                                    <input type="text" placeholder='East Cannal Road Faisalabad' className='form-control form-control-sm ' />
                                                </div>
                                            </div>
                                            <div className="row mx-2 mb-3">
                                                <div className="col text-end">
                                                    <button className='btn btn-white border'> <i className="fa-solid fa-pen" style={{ color: "#156829" }}></i><span className='ms-2' style={{ color: "#156829" }}>Edit Profile</span> </button>
                                                </div>
                                            </div>
                                        </form>


                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
