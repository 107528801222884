import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Accounts from './Accounts';
import CashBook from './CashBook';
import Ledger from './Ledger';
import Payable from './Payable';
import RecieveAble from './RecieveAble';
import AllAccounts from './AllAccounts';

import Profile from './Profile';
import SideBar from '../../components/Sidebar';
import PayReceiveAble from './PayReceiveable/PayReceiveAble';
import Topbar from '../../components/Header/Topbar';
import Signup from '../Authentication/Signup/Signup';
import AccountantSignup from '../Authentication/AccountantSignup/AccountantSignup';
import AdminSignup from '../Authentication/AdminSignup/AdminSignup';
import PrivateRoute from '../../components/PrivateRoute';
// import PrivateRoute from '../../components/PrivateRoute';

export default function Index() {
  return (
    <>
      <div className="container-fluid py-2 py-sm-4 px-2 px-sm-5 vh-100  " style={{ background: "#b6e2c6" }}>
        <div className="continer rounded-3 bg-white" style={{ height: "100%" }}>
          <div className="row mx-0">
            <Topbar />
            <div className="col-2 col-sm-3 col-lg-2 px-0">
              <SideBar />

            </div>
            <div className="col mx-1 mx-lg-0  abc px-0 mt-1  bg-light" style={{ overflow: "scroll", height: "82vh", borderRadius: "0px 0px 10px 0px" }} >
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='accounts' element={<PrivateRoute Component={Accounts} allowedRoles={["admin"]} />} />
                <Route path='cashbook' element={<PrivateRoute Component={CashBook} allowedRoles={["admin"]} />} />
                <Route path='ledger' element={<Ledger />} />
                <Route path='payable' element={<Payable />} />
                <Route path='recieveable' element={<RecieveAble />} />
                <Route path='pay-receiveable' element={<PayReceiveAble />} />
                <Route path='trial-balance' element={<AllAccounts />} />
                <Route path='register-admin' element={<PrivateRoute Component={AdminSignup} allowedRoles={["SuperAdmin"]} />} />
                <Route path='register-accountant' element={<PrivateRoute Component={AccountantSignup} allowedRoles={["admin", "SuperAdmin"]} />} />
                <Route path='profile' element={<Profile />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </>


  )
}
