
import './App.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import Routes from './pages/Routes'
import { useAuthcontexts } from './contexts/Authcontexts';



function App() {

  const { isAppLoading } = useAuthcontexts()


  if (isAppLoading)
    return (
      <div className="d-flex justify-content-center align-items-center min-vh-100">
      <div className="spinner-border text-success" role="status" style={{ height: '3rem', width: "3rem" }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
    )
  return (
    <>
        <Routes />
    </>
  );
}

export default App;
