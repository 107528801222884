import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthcontexts } from '../contexts/Authcontexts'
import axios from 'axios';
import { URL } from '../Constants';


const FetchCustomerContext = createContext();

export default function FetchCustomerContextProvider({ children }) {

    const [customers, setCustomers] = useState([]);
    const { user } = useAuthcontexts();

    const showCustomers = async () => {
        const { shopId } = user;

        if (shopId) {
            axios.post(`${URL}/customer/get`, { shopId: shopId })
                .then((res) => {
                    const { data } = res
                    setCustomers(data)
                })
                .catch((err) => {
                    console.log('err', err)
                })
        } else {
            console.error("User shopId is undefined.");
        }
    }

    useEffect(() => {
        showCustomers();
    }, [user]);

    return (
        <FetchCustomerContext.Provider value={{ customers, setCustomers, showCustomers }}>
            {children}
        </FetchCustomerContext.Provider>
    );
}

export const useFetchCustomerContext = () => useContext(FetchCustomerContext);
