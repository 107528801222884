import React, { useState } from 'react';
import "./cashbook.scss";

import AddCashBook from './AddCashBook';
import FilterCashBook from './FilterCashBook';

import { FaFilter } from "react-icons/fa"
import { IoIosAdd } from "react-icons/io"

export default function CashBook() {

    const [state, setState] = useState(0);

    const handleClick = (i) => {
        setState(i)
    }

    return (
        <>
            <div className="container bg-light">
                <div className="row">
                    <div className="col m-2 bg-white overflow-scroll abc rounded-3" style={{height:"100%"}}>
                            <div className="row">

                            <div className='mx-0 mt-3 d-flex flex-row justify-content-between' id='cashVocher'>
                                <div className=''><h4 className='mt-2'>CashBook</h4></div>
                                <div id='btnStyle'>
                                    <button className='btn btn-outline-secondary px-3 me-2' style={{ color: "#a6a6a6" }} onClick={() => handleClick(2)}> <FaFilter /> <span className='d-none d-sm-inline'>Filter</span></button>
                                    <button className='btn btn-light px-3 text-white' style={{ background: "#005a16" }} onClick={() => handleClick(1)}> <IoIosAdd /> <span className='d-none d-sm-inline'>Add</span></button>
                                </div>
                            </div>
                            </div>
                            <hr className='bg-secondary' />


                            <div className='row'>
                                {state === 1 ?
                                    <AddCashBook />
                                    : <FilterCashBook />
                                }
                            </div>
                    </div>
                </div>
            </div>
        </>
    )
}
