import React from 'react'



import { HiOutlineUserGroup } from 'react-icons/hi';
import { SiAmazonpay } from 'react-icons/si';
import { BsFillCreditCardFill } from 'react-icons/bs';

import "./homecomponents.scss";

import { useFetchCustomerContext } from '../../contexts/FetchCustomersContext';
import { useAuthcontexts } from '../../contexts/Authcontexts';



export default function HomeComponents() {

    // fetch Data of users

    const { user } = useAuthcontexts();

    // fetch Data of Cutomers

    const { customers } = useFetchCustomerContext();

    const payables = customers.filter((entity) => { return entity.typeOfAccount === "Payable/Craditors" });
    const reciveables = customers.filter((entity) => { return entity.typeOfAccount === "Reaciveable/debitors" });

    const sortedCustomers = [...customers].sort((a, b) => b.accountNo - (a.accountNo));

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">

                        <div className="row mt-5">
                            <h5 className='mx-3 mx-md-5'>Welcome to Our Finencial Accounts System</h5>
                            <h4 className='mx-3 mx-md-5 mt-3'> Nice to see you... <span style={{ textTransform: 'capitalize' }}> {user.fullName}</span></h4>
                        </div>
                        <div className="row mx-3 mx-md-5 mt-4  d-flex justify-content-between">
                            <div className="col-12 col-md-6 mb-2  px-0 py-3 py-md-0 mt-2 mt-xl-0">
                                <div className='mx-2 p-3 bg-white fw-bold rounded' style={{ height: "120px" }}>
                                    <h6>ALL ACCOUNT <span className='ps-2 pt-0'><HiOutlineUserGroup size={22} /></span></h6>
                                    <span className='text-danger'>
                                        Active : {
                                            customers.length
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-2 px-0 py-3 py-md-0 mt-2 mt-xl-0 ">
                                <div className='mx-2 p-3 bg-white fw-bold rounded' style={{ height: "120px" }}>
                                    <h6>PAYABLE ACCOUNTS <span> <SiAmazonpay size={22} /> </span></h6>
                                    <span className='text-danger'>
                                        Active : {
                                            payables.length
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-2 px-0 py-3 py-md-0 mt-2 mt-xl-0">
                                <div className='mx-2 p-3 bg-white fw-bold rounded' style={{ height: "120px" }}>
                                    <h6>RECIVEABLE ACCOUNTS <span> <BsFillCreditCardFill size={18} /></span></h6>
                                    <span className='text-danger'>
                                        Active : {
                                            reciveables.length
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-2 px-0 py-3 py-md-0 mt-2 mt-xl-0">
                                <div className='mx-2 p-3 bg-white fw-bold rounded' style={{ height: "120px" }}>
                                    <h6>Other ACCOUNTS</h6>
                                    <span className='text-danger'>
                                        Active : {
                                            customers.length - (reciveables.length + payables.length)
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-5 p-2 mx-2 rounded-3 d-none d-md-block " style={{ height: 700 }}>

                        <h6>Latest list of Accounts</h6>

                        <div className="table-responsive rounded">
                            <table className="table">
                                <thead>
                                    <tr className='' style={{ background: "#b6e2c6", color: "#005a16" }}>
                                        <th>Sr.No</th>
                                        <th>AC.No</th>
                                        <th>Account Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sortedCustomers.slice(0,15).map((document, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th scope="row"> {i + 1} </th>
                                                    <td>{document.accountNo}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{document.fullName}</td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
