import React, { useState, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print';
import './PayReceiveAble.scss'
import { useFetchCustomerContext } from '../../../contexts/FetchCustomersContext';
import { useFetchCashBook } from '../../../contexts/FetchCashBook';
import { useAuthcontexts } from '../../../contexts/Authcontexts';
import { AccountSection, calculateBalances } from '../../../components/GlobalFunctions';

export default function PayReceiveAble() {

  const [state, setState] = useState([]);
  const [isloading, setIsLoding] = useState(false);
  const componenRef = useRef();

  // fetch Data of Cutomers

  const { user } = useAuthcontexts()
  const { customers } = useFetchCustomerContext();
  const { getCashBook } = useFetchCashBook();

  useEffect(() => {
    const calculatedBalance = calculateBalances(customers, getCashBook)
    setState(calculatedBalance)
  },[])

  const payAbles = state.filter((customer) => { return customer.customerType === 'Payable/Craditors' })
  const filterPayables = payAbles.filter((customer) => { return customer.balance !== 0 })

  const receiveAbles = state.filter((customer) => { return customer.customerType === 'Reaciveable/debitors' })
  const filterReceiveAbles = receiveAbles.filter((customer) => { return customer.balance !== 0 })

  // set Current date and time 

  const date = new Date();

  const currentDate = date.toDateString();
  const currentTime = (date.toLocaleTimeString());


  const handlePrint = useReactToPrint({
    content: () => componenRef.current,
    documentTitle: "Pay/Receiveable"
  })

  const combinedData = [
    { type: 'Payable/Craditors', accounts: filterPayables },
    { type: 'Reaciveable/debitors', accounts: filterReceiveAbles },
  ];
  return (
    <>
      <div className='container'>
        <div className="row m-0 m-md-1 bg-white rounded vh-100 overflow-scroll abc">
          <div className="col-12 mt-2">
            <div ref={componenRef} className='afterPrint'>

              {
                isloading ?
                  <div className="d-flex justify-content-center align-items-center min-vh-100">
                    <div className="spinner-border text-success" role="status" style={{ height: '3rem', width: "3rem" }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div> :
                  <div>
                    <div className='row'>
                      <div className="col-5 d-none d-md-block abcd" style={{ color: "#0c6321" }}>
                        <h4 className='text-capitalize'>{user.companyName ? user.companyName : 'Zaman Ali'}</h4>
                      </div>
                      <div className="col text-end mt-3 mt-md-2"><div className='fw-bold' style={{ color: "#0c6321" }}>{currentDate} <span className='d-none d-md-inline-block abcd'>{currentTime}</span></div>          </div>
                    </div>

                    <div className='row my-2'>
                      <div className="col text-center" style={{ color: "#0c6321" }}><h4>Pay / Recieveable Accounts</h4></div>
                    </div>
                    <AccountSection title="" data={combinedData} />
                  </div>
              }
            </div>
            <div className='row d-flex justify-content-end mb-2'>
              <div className="col-5 col-md-2 text-center"> <button className='btn fw-bold w-100 border rounded-3' style={{ color: "#0c6321", background: '#b6e2c6' }} onClick={handlePrint}>Print </button></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
