import React, { createContext, useContext, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'



const Authcontexts = createContext()


export default function AuthcontextsProvider({ children }) {
  const [user, setUser] = useState({});
  const [token, setToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAppLoading, setIsAppLoading] = useState(true);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      setToken(authToken);
      const decoded = jwtDecode(authToken);
      setUser(decoded.user);
      setIsAuthenticated(true);
    }
    setIsAppLoading(false);
  }, []);
  return (
    <Authcontexts.Provider value={{ user, token, setUser, isAuthenticated, setIsAuthenticated, isAppLoading }}>
      {children}
    </Authcontexts.Provider>
  );
}


export const useAuthcontexts = () => useContext(Authcontexts)


