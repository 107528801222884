import React from 'react'
import CashBook from './CashBook';


export default function Index() {
  return (
    <>
      <CashBook />
    </>
  )
}
