import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import "./topbar.scss"
import { useAuthcontexts } from '../../contexts/Authcontexts'

const Topbar = ({ handleChange }) => {

    // fetch Data of users

    const { user } = useAuthcontexts();
    return (
        <>

            <div className="container-fluid">
                <div className="row px-0">
                    <div className="col">
                        <nav className="navbar navbar-expand-lg bg-white">
                            <div className="container-fluid py-1 ps-0">
                                {/* <div className='d-flex felx-row'>
                                    <div className='mt-2 ms-0'>

                                        <button className='btn border d-block d-md-none' onClick={handleChange}><AiOutlineBars /></button>
                                    </div>
                                    <div>

                                        <img src={require("../../assests/images/green gt.jpg")} alt="logo" className='ms-2' style={{ width: '50px' }} />
                                    </div>
                                </div> */}
                                <a className="navbar-brand" href="https://zaman-cd-accounts.web.app/">

                                    <span className='fw-bold ms-0 ms-md-2 fs-4 text-capitalize'>{user?.companyName ? user.companyName : 'Zaman Ali'}</span>
                                </a>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" style={{ background: "white", color: "#005a16", outline: "none" }}>
                                    <BsThreeDotsVertical />
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                    </ul>
                                    <div className='d-flex flex-row'>

                                        <div className='mx-1 mx-lg-3 mt-2 mt-lg-0'>
                                            <h6 className='mb-0' style={{ textTransform: 'capitalize' }}> {user?.fullName} </h6>
                                            <p className='mb-0'> {user?.email}</p>
                                        </div>
                                        <div className='fs-3 ms-2 mt-2 mt-lg-0'>
                                            <i className="fa-regular fa-circle-user"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>

    )
}
export default Topbar;