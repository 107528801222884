/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
// import { firestore } from '../../../../config/firebase';
// import { deleteDoc, doc, serverTimestamp, updateDoc, } from 'firebase/firestore';



import { AiTwotoneDelete } from 'react-icons/ai'
import { BsFillPencilFill } from 'react-icons/bs'
import { BiSearch } from 'react-icons/bi'


import { message } from 'antd';
import { useAuthcontexts } from '../../../../contexts/Authcontexts';
import { useFetchCustomerContext } from '../../../../contexts/FetchCustomersContext';
import axios from 'axios';
import { URL } from '../../../../Constants';




const initialState = { accountNo: 0, firstName: "", lastName: "", typeOfAccount: "", openingBalance: 0, email: "", phoneNumber: 0, district: "", adress: "" }


const accountType = ["", "Admin", "Bank Balance", "Payable/Craditors", "Reaciveable/debitors", "Payable/Salary", "Sale", "Puscheses", "Admin Expense", "Fix Assets"];

const Cities = ["", "66 Tandla", "Ada 5 Chak", "Adda jahan khan", "Aminpur Bangla", "Islamabad", "Ahmed Nager", "Ahmadpur Sharkia", "Ali Khan", "Alipur", "Arifwala", "Attock", "Bhera", "Bhalwal", "Bahawalnagar",
    "Bahawalpur", "Bangla Gogera", "BawaChak", "Bhakkar", "Burewala", "Chillianwala", "Chakwal", "Chichawatni", "Chiniot",
    "Chishtian", "Chowk Munda", "Daska", "Darya Khan", "Dahranwala", "Dairy", "Dera Ghazi", "Dhamthal", "Dhaular", "Dina", "Dinga", "Dipalpur",
    "Faisalabad", "Farooqabad", "Fateh Jhang", "Faqir Wali", "Fort Abbas", "Ghakhar Mandi", "Gojra", "Gujranwala", "Gujrat", "Gujar Khan", "Hafiz wala", "Hafizabad",
    "Haroonabad", "Hasilpur", "Haveli", "Lakha", "Jalalpur", "Jahanian mandi", "Jattan", "Jampur", "Jaranwala", "Jhang", "Jhelum",
    "Kalabagh", "Karor Lal", "Kasur", "Kamalia", "Kamoke", "Khanewal", "Khanpur", "Kharian", "Khushab", "Kot Adu",
    "Jauharabad", "Lahore", "Lalamusa", "Layyah", "Liaquat Pur", "Lalazar", "Lodhran", "Malakwal", "Mamoori", "Mailsi",
    "Mandi Bahauddin", "mian Channu", "Mianwali", "Multan", "Murree", "Muridke", "Mianwali Bangla", "Muzaffargarh",
    "Narowal", "Okara", "Renala Khurd", "Pakpattan", "Pattoki", "Piplan", "Pir Mahal", "Qaimpur", "Qila Didar",
    "Rabwah", "Raiwind", "Rajana", "Rajanpur", "Rahim Yar", "Rawalpindi", "Sadiqabad", "Safdarabad", "Sahiwal", "Sangla Hill",
    "Sarai Alamgir", "Sargodha", "Shakargarh", "Sheikhupura", "Samundri", "Sialkot", "Sohawa", "Soianwala", "Siranwali", "Talagang",
    "Taxila", "Toba Tek", "Vehari", "Wah Cantonment", "Wazirabad", "Badin", "Bhirkan", "Rajo Khanani", "Chak",
    "Dadu", "Digri", "Diplo", "Dokri", "Ghotki", "Haala", "Hyderabad", "Islamkot", "Jacobabad", "Jamshoro",
    "Jungshahi", "Kandhkot", "Kandiaro", "Karachi", "Kashmore", "Keti Bandar", "Khairpur", "Kot Radha Kishan", "Kotri", "Larkana",
    "Matiari", "Mehar", "Mirpur Khas", "Mithani", "Mithi", "Mehrabpur", "Mandi yazman", "Moro", "Nagarparkar", "Naudero",
    "Naushahro Feroze", "Naushara", "Nawabshah", "Nazimabad", "Patizan", "Pasroor", "Qambar", "Qasimabad", "Ranipur", "Ratodero",
    "Rohri", "Sadhar", "Sakrand", "Sanghar", "Shahbandar", "Shahdadkot", "Shahdadpur", "Shahpur", "Shah Koot", "Shikarpaur",
    "Sukkur", "Tangwani", "Tando Adam", "Tando Allahyar", "Tando Muhammad", "Thatta", "Umerkot", "Warah",
    "Abbottabad", "Adezai", "Alpuri", "Akora Khattak", "Ayubia", "Banda Daud", "Bannu", "Batkhela", "Battagram",
    "Birote", "Chakdara", "Charsadda", "Chitral", "Daggar", "Dargai", "Darya Khan", "dera Ismail", "Doaba",
    "Dir", "Drosh", "Hangu", "Haripur", "Karak", "Kohat", "Kulachi", "Lakki Marwat", "Latamber", "Madyan",
    "Mansehra", "Mardan", "Mastuj", "Mingora", "Nowshera", "Paharpur", "Pabbi", "Peshawar", "Saidu Sharif",
    "Shorkot", "Shewa Adda", "Swabi", "Swat", "Tangi", "Tank", "Thall", "Tulamba", "Timergara", "Tordher", "Awaran",
    "Barkhan", "Chagai", "Dera Bugti", "Gwadar", "Harnai", "Jafarabad", "Jhal Magsi", "Kacchi", "Kalat", "Kech",
    "Kharan", "Khuzdar", "Killa Abdullah", "Killa Saifullah", "Kohlu", "Lasbela", "Lehri", "Loralai", "Mastung",
    "Musakhel", "Nasirabad", "Nushki", "Panjgur", "Pishin valley", "Quetta", "Sherani", "Sibi", "Sohbatpur",
    "Washuk", "Zhob", "Ziarat"];

const districts = ['', 'Attock', 'Bahawalnagar', 'Bahawalpur', 'Bhakkar', 'Chakwal', 'Chiniot', 'Faisalabad', 'Gujranwala', 'Gujrat',
    'Hafizabad', 'Jhelum', 'Khnewal', 'Khushab', 'Layyah', 'Lodhran', 'Mandi Bahauddin',
    'Mianwali', 'Multan', 'Muzaffargarh', 'Nankana Sahib', 'Okara', 'Pakpattan', 'Rahim Yar Khan', 'Rajanpur', 'Rawalpindi', 'Sahiwal',
    'Srgodha', 'Sheikhupura', 'Toba Tek singh', 'Vehari'];


const initialvalue = 30;

export default function ViewAccounts() {

    const [state, setState] = useState('');
    const [rows, setRows] = useState(initialvalue);
    const [currentPage, setCurrentPage] = useState(1);
    const [isloading, setIsLoding] = useState(false);
    const [findSearch, setFindSearch] = useState({ search: "" });

    // fetch Data of users
    const { user } = useAuthcontexts();

    // fetch Data of Cutomers
    const { customers, setCustomers } = useFetchCustomerContext();

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value });

    }
    const handleSearch = e => {
        setFindSearch({ ...state, [e.target.name]: e.target.value })
    }
    const handleChangeRows = e => {
        setRows({ ...state, [e.target.name]: e.target.value })
    }

    let { search } = findSearch

    // search = Number(search)

    let searchedCustomers = [];
    if (search === "") {
        searchedCustomers = [...customers]
    }
    else {

        searchedCustomers = customers.filter((customer) => customer.fullName.toLowerCase().includes(search.toLowerCase()))
    }
    const sortedCustomers = [...searchedCustomers].sort((a, b) => a.accountNo - (b.accountNo));


    // Update Customer

    const handleUpdate = (customer) => {
        setState(customer)
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        const updatedData = {
            ...state
        }
        updatedData.openingBalance = Number(updatedData.openingBalance);
        updatedData.accountNo = Number(updatedData.accountNo);
        updatedData.fullName = updatedData.firstName + " " + updatedData.lastName;
        updatedData.fullName = updatedData.fullName.trim()
        if (updatedData.length < 3) {

            return alert("Please Enter Your FirstName Correctly")
        }


        const updatedTodos = customers.map(oldCustomers => {
            if (oldCustomers.id === updatedData.id)
                return updatedData
            return oldCustomers
        })
        setCustomers(updatedTodos)

        axios.post(`${URL}/customer/update/${updatedData.id}`, updatedData)
            .then((res) => {
                message.success("Customer updated successfully")
            })
            .catch((error) => {
                if (error.response.status)
                    message.error(error.response.data.error)
                else
                    message.error(error.message)
            })
    }

    // Delete Customer 

    const handleDelete = async (user) => {
        if (user.fullName.toLowerCase() === "sale" || user.fullName.toLowerCase() === "purchase") {
            return message.error("You Can't Delete This Account")
        }
        // try {
        //     await deleteDoc(doc(firestore, "customers", user.id));
        //     let customersAfterDelete = customers.filter(doc => doc.id !== user.id)
        //     setCustomers(customersAfterDelete)
        //     message.success("Account Deleted Successfully")

        // } catch (e) {
        //     console.error("Error adding document: ", e);
        //     message.error("SomeThing went Wrong While Deleting Account")
        // }
    }

    const rowsPerPage = 30;
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage;
    const data = sortedCustomers.slice(firstIndex, lastIndex);
    const npages = Math.ceil(sortedCustomers.length / rowsPerPage);
    const number = [...Array(npages + 1).keys()].slice(1)

    const handleChangePage = (id) => {
        setCurrentPage(id)
    }
    const handlePre = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const handleNext = () => {
        if (currentPage !== npages) {
            setCurrentPage(currentPage + 1)
        }
    }

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <div className='mb-3 d-flex flex-row justify-content-between'>
                            <div className='d-flex flex-row me-1'>
                                <span className=' d-none d-sm-inline fw-bold me-2 mt-1'>Show </span>
                                <select className="form-control-sm ms-lg-0" name='show' onChange={handleChangeRows}>
                                    <option>01</option>
                                    <option>05</option>
                                    <option>10</option>
                                    <option>15</option>
                                    <option>20</option>
                                </select>
                                <span className='ms-2 mt-1 d-none d-sm-inline  fw-bold'>Rows</span>

                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-3">
                        <div className="input-group mb-3">
                            <span className="input-group-text bg-white" id="basic-addon1"><BiSearch /></span>
                            <input type="text" className="form-control rounded-end" placeholder="Search by name" name='search' aria-describedby="basic-addon1" onChange={handleSearch} />
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="container-fluid">
                <div className="row mt-4">
                    <div className="col-12 mb-3">
                        <div className="table-responsive rounded">
                            <table className="table table-sm">
                                <thead>
                                    <tr className='' style={{ background: "#c1e6c3", color: "#005a16" }}>
                                        <th>Sr.No</th>
                                        <th>AC#</th>
                                        <th>AC Name</th>
                                        <th>AC Type</th>
                                        <th>City</th>
                                        <th className='text-center'>Cell NO#</th>
                                        <th className='text-center'>Opening Balance</th>
                                        <th>Created By</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.slice(0, rows.show).map((document, i) => {
                                            return (
                                                <tr key={i}>
                                                    <th scope="row"> {i + 1} </th>
                                                    <td>{document.accountNo}</td>
                                                    <td style={{ textTransform: 'capitalize' }}>{document.fullName}</td>
                                                    <td>{document.typeOfAccount}</td>
                                                    <td>{document.city}</td>
                                                    <td className='text-center'>{document.phoneNumber}</td>
                                                    <td className='text-center'>{document.openingBalance?.toLocaleString()}</td>
                                                    <td>{new Date(document.dateOfCreated).toLocaleDateString()}</td>
                                                    {/* <td className='text-center'>{document.openingBalance}</td> */}
                                                    <td className='text-center'>
                                                        <button className='btn btn-info p-1 py-0 rounded-2 me-1' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => { handleUpdate(document) }}> <span className='text-white my-1 d-flex align-items-center'><BsFillPencilFill size={16} /></span> </button>
                                                        {/* <button className='btn btn-danger p-1 py-0 rounded-2' onClick={() => { handleDelete(document) }}> <span className='text-white my-1 d-flex align-items-center'><AiTwotoneDelete size={16} /></span> </button> */}
                                                    </td>
                                                </tr>
                                            )
                                        })

                                    }
                                </tbody>
                            </table>
                        </div>
                        {
                            data.length ?
                                <nav>
                                    <ul className="pagination pagination-sm justify-content-end">
                                        <li className="page-item">
                                            <button className="page-link" aria-label="Previous" onClick={handlePre}>
                                                Prev
                                            </button>
                                        </li>
                                        {
                                            number.map((n, i) => (
                                                <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                    <button className="page-link" onClick={() => handleChangePage(n)}>{n}</button>
                                                </li>
                                            ))
                                        }
                                        <li className="page-item">
                                            <button className="page-link" aria-label="Next" onClick={handleNext}>
                                                Next
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                                : ""
                        }
                    </div>
                </div>

                {/* <!-- Modal --> */}

                <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="exampleModalLabel">Update Account</h1>
                                <button type="button" className="btn-close btn btn-light" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleSubmit}>
                                    <div className="row mx-2 mb-md-3 pt-2">
                                        <div className="col-12 mb-2 col-md-4">
                                            <label style={{ color: "#156829" }}>ID  <span className='text-danger'>*</span></label>
                                            <input type="number" placeholder='0000' className='form-control form-control-sm  ' name='accountNo' value={state.accountNo} onChange={handleChange} />
                                        </div>
                                        <div className="col-12 mb-2 col-md-4">
                                            <label style={{ color: "#156829" }}>First Name <span className='text-danger'>*</span></label>
                                            <input type="text" placeholder='Zaman' name='firstName' className='form-control form-control-sm ' value={state.firstName} required onChange={handleChange} />
                                        </div>
                                        <div className="col-12 mb-2 col-md-4">
                                            <label style={{ color: "#156829" }}>Last Name</label>
                                            <input type="text" placeholder='Ali' name='lastName' className='form-control form-control-sm ' value={state.lastName} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mx-2 mb-md-3">
                                        <div className="col-12 mb-2 col-md-6">
                                            <label style={{ color: "#156829" }}>Type of Accounts <span className='text-danger'>*</span></label>
                                            <br />
                                            <select name="typeOfAccount" id="" className='form-control form-control-sm' value={state.typeOfAccount} onChange={handleChange}>
                                                {
                                                    accountType.map((acc, i) => {
                                                        return (
                                                            <option key={i} className=''>{acc}</option>

                                                        )
                                                    })
                                                }
                                            </select>
                                            {/* <input type="text" placeholder='Creditors/Debitors' name='typeOfAccount' className='form-control form-control-sm ' required onChange={handleChange}/> */}
                                        </div>
                                        <div className="col-12 mb-2 col-md-6">
                                            <label style={{ color: "#156829" }}>Opening Balance <span className='text-danger'>*</span></label>
                                            <input type="number" placeholder='500000' name='openingBalance' className='form-control form-control-sm ' value={state.openingBalance} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mx-2 mb-md-3">
                                        <div className="col-12 mb-2 col-md-6">
                                            <label style={{ color: "#156829" }}>Email</label>
                                            <input type="email" placeholder='Sweetzamanali@gmail.com' name='email' className='form-control form-control-sm ' value={state.email} onChange={handleChange} />
                                        </div>
                                        <div className="col-12 mb-2 col-md-6">
                                            <label style={{ color: "#156829" }}>Phone Number  <span className='text-danger'>*</span></label>
                                            <input type="number" placeholder='+92 306 6525299' name='phoneNumber' className='form-control form-control-sm ' value={state.phoneNumber} required onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mx-2 mb-md-3">
                                        <div className="col-12 col-md-6 mb-2">
                                            <label style={{ color: "#156829" }}>District  <span className='text-danger'>*</span></label>
                                            <br />
                                            <select name="district" id="" className='form-control form-control-sm ' value={state.district} onChange={handleChange}>
                                                {
                                                    districts.map((acc, i) => {
                                                        return (
                                                            <option key={i} className=''>{acc}</option>

                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="col-12 col-md-6 mb-2">
                                            <label style={{ color: "#156829" }}>City <span className='text-danger'>*</span></label>
                                            <br />
                                            <select name="city" id="" className='form-control form-control-sm ' value={state.city} onChange={handleChange}>
                                                {
                                                    Cities.map((acc, i) => {
                                                        return (
                                                            <option key={i} className=''>{acc}</option>

                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="row mx-2 mb-4">
                                        <div className="col-12 mb-2">
                                            <label style={{ color: "#156829" }}>Adress <span className='text-danger'>*</span></label>
                                            <input type="text" placeholder='East Cannal Road Faisalabad' name='adress' className='form-control form-control-sm ' value={state.adress} onChange={handleChange} />
                                        </div>
                                    </div>
                                    <div className="row mx-2">
                                        {
                                            !isloading ?
                                                <div className="col text-end">
                                                    <div className="modal-footer">
                                                        <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                                        <button type="submit" className="btn btn-success" data-bs-dismiss="modal" aria-label="Close">Update</button>
                                                    </div> </div>
                                                : <div className="col text-end">
                                                    <button className="btn btn-success border-success mb-3" type="button" disabled>
                                                        <span className="spinner-border text-success spinner-border-sm mx-2" role="status" aria-hidden="true"></span>
                                                        Loading...
                                                    </button>
                                                </div>
                                        }
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
