import React from 'react'
import { Route, Routes } from 'react-router-dom';

import Login from './Login'
import ForgetPassword from './ForgetPassword/ForgetPassword';
// import Signup from './Signup'
// import AccountantSignup from './AccountantSignup'
// import PrivateRoute from '../../components/PrivateRoute';

export default function Index() {
  return (
    <Routes>
      <Route path='login' element={<Login />} />
      {/* <Route path='register' element={<Signup />} /> */}
      {/* <Route path='register-accountant' element={<PrivateRoute Component={AccountantSignup} allowedRoles={["admin"]} />} /> */}
      <Route path='forgetpassword' element={<ForgetPassword />} />
      <Route path="*" element={<h1 style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}> <span className='text-danger'>404. </span> Page not found</h1>} />
    </Routes>
  )
}
