import React, { useState } from 'react';
import { useAuthcontexts } from '../../../contexts/Authcontexts';
import { message } from 'antd';
import "../Signup/signup.scss";
import axios from 'axios';
import { URL } from '../../../Constants';




const initialstate = { fullName: "", email: "", password: "" }
export default function AccountantSignup() {

  const [state, setState] = useState(initialstate);
  const { user, token } = useAuthcontexts();


  const handlechange = e => {

    setState(s => ({ ...s, [e.target.name]: e.target.value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { fullName, email, password } = state;



    if (fullName.length < 3) {
      message.error("Your name should be more than 3 Characters")
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      return message.error("Please enter a valid Email")
    }

    // regular expressions to validate password
    var lowerCase = /[a-z]/g;
    var upperCase = /[A-Z]/g;
    var numbers = /[0-9]/g;
    if (!password.match(lowerCase)) {
      return message.error("Password should contains lowercase letters!");
    } else if (!password.match(upperCase)) {
      return message.error("Password should contain uppercase letters!");
    } else if (!password.match(numbers)) {
      return message.error("Password should contains numbers also!");
    } else if (password.length < 8) {
      return message.error("Password length should be more than 8.");
    } else {
      message.success("Password is strong!");
    }



    // setIsProcessing(true)
    // createUserWithEmailAndPassword(auth, email, password)
    //   .then((userCredential) => {
    //     const newUser = userCredential.user;
    //     setIsAuthenticated(false)
    //     createUserProfile(newUser)
    //     navigate('/')
    //   })
    //   .catch(err => {
    //     message.error("Something went wrong while creating user")
    //     console.error(err)
    //   })
    //   .finally(() => {
    //     // setIsProcessing(false)
    //   })
  }


  const createAccountant = async (e) => {

    e.preventDefault()

    let { fullName, email, password } = state

    fullName = fullName.toLowerCase().trim();

    if (fullName.length < 3) {
      message.error("Your name should be more than 3 Characters")
    }

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      return message.error("Please enter a valid Email")
    }
    password = password.trim()

    const id = Math.random().toString(36).slice(2);

    const userData = {
      companyName: user.companyName,
      fullName, email, password,
      id,
      shopId: user.id,
      dateCreated: new Date().toISOString().slice(0, 10),
      status: "active",
      roles: "accountant"

    }

    axios.post(`${URL}/user/register`, userData, {
      headers: {
        'token': token
      }
    })
      .then((res) => {
        message.success("Accountant Register Successfully")
        setState(initialstate)
      }).catch((err) => {
        const errorMessage = err.response?.data?.error || "Something went wrong. Please try again.";
        message.error(errorMessage);
      });
  }


  return (
    <>
      <section style={{ background: "#f8f9fa" }}>
        <div className="container p-4 mt-0 mt-md-2">
          <div className="row bg-white rounded-3">
            <div className="col-12 p-1 p-md-4">

              <h4 className='text-center my-3 my-md-0'>Register New Accountant</h4>

              <p className='my-3 mx-3' style={{ color: "#9c9c9c" }}>Elevate Your Account's Management Game </p>

              <form onSubmit={createAccountant} id='registerForm'>

                <div className="row mx-2 mb-3 ">
                  <div className="col-12 col-md-6 mb-3 mb-md-0">
                    <label className='fw-bold'>Company Name:</label>
                    <input type="text" className='form-control ' name='companyName' placeholder='Company Title' value={user.companyName} disabled onChange={handlechange} />
                  </div>
                  <div className="col-12 col-md-6">
                    <label className='fw-bold'>Accountant Name:</label>
                    <input type="text" className='form-control ' name='fullName' value={state.fullName} placeholder='Your Good Name' onChange={handlechange} />
                  </div>
                </div>

                <div className="row mx-2 mb-3 ">
                  <div className="col">
                    <label className='fw-bold'>Email:</label>
                    <input type="email" className='form-control ' name='email' value={state.email} placeholder='Name@company.com' onChange={handlechange} />
                  </div>
                </div>

                <div className="row mx-2 mb-3">
                  <div className="col">
                    <label className='fw-bold'>Password:</label>
                    <input type="password" className='form-control' name='password' value={state.password} placeholder='Min. 8 characters' onChange={handlechange} />
                  </div>
                </div>

                <div className="row mx-2 mt-5 justify-content-end">
                  <div className="col-12 col-md-3">
                    <button className='btn btn-success w-100' type='submit'>Sign Up</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </section>

    </>

  )
}
