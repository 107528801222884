import React, { useState } from 'react'
import { Carousel, message } from 'antd';
import { Link } from 'react-router-dom';

import "./Login.scss";
import { useAuthcontexts } from '../../../contexts/Authcontexts';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { URL } from '../../../Constants';


const initialstate = { email: "", password: "" }
function LoginForm() {

  const year = new Date().getFullYear();

  const [state, setState] = useState(initialstate);
  const [isloading, setIsLoding] = useState(false)
  const { setIsAuthenticated, setUser } = useAuthcontexts();

  const handlechange = e => {

    setState(s => ({ ...s, [e.target.name]: e.target.value }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoding(true)
    const { email, password } = state

    const data = {
      email, password
    }

    axios.post(`${URL}/user/login`, data)
      .then((res) => {

        const token = res.data.token;
        console.log("token", token);
        localStorage.setItem("authToken", token)

        const decoded = jwtDecode(token);
        setUser(decoded.user)

        setIsAuthenticated(true)
        message.success("User Logged In Successfully")
      }).catch((err) => {
        console.log('err', err)
        const errorMessage = err.response?.data?.error || "Something went wrong. Please try again.";
        message.error(errorMessage);
      });
    setIsLoding(false)
  }


  const contentStyle = {
    height: "600px",
    color: '#fff',
  };
  return (
    <>
      <section className='vh-100 d-flex align-items-center' style={{ background: "#b7e4c7" }}>
        <div className="container px-5">
          <div className="row mt-4 bg-white rounded-3">
            <div className="col-12 col-lg-6">
              <div className="m-lg-4 m-md-3  p-sm-2 p-md-5 p-lg-4 p-xl-4">
                <div className='row'>
                  {/* <div className="col-2 ms-2 mb-3">
                    <img src={require('../../../assests/images/green gt.png')} alt="logo" style={{ width: '50px' }} />
                  </div> */}
                  <div className="col mt-3 ps-4 ms-1 ps-md-4">
                    <h4>Zaman Accounts</h4>
                  </div>
                </div>
                <p className='mb-4 mx-3' style={{ color: "#9c9c9c" }}>Elevate Your Account's Management Game </p>
                <form onSubmit={handleSubmit} id='formStyle'>
                  <div className="row mx-2 mb-4 ">
                    <label className='fw-bold'>Email:</label>
                    <input type="email" className='form-control' name='email' placeholder='Sweetzamanali@gamil.com' required onChange={handlechange} />
                  </div>
                  <div className="row mx-2 mb-4">
                    <label className='fw-bold'>Password:</label>
                    <input type="password" className='form-control' name='password' placeholder='Min. 8 characters' onChange={handlechange} />
                  </div>
                  <div className="row mx-2">

                    <div className="form-check d-flex justify-content-between ms-2">
                      <div>
                        <input className="form-check-input bg-success" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault" style={{ fontWeight: 500 }}>
                          Remember Me
                        </label>
                      </div>
                      <Link to='/auth/forgetpassword' className='text-decoration-none'> Forgot password?</Link>
                    </div>
                  </div>
                  {
                    isloading ?
                      <div className="row mx-2 my-4 ms-3">
                        <button className="btn" type="submit" style={{ background: "#005a16", color: "white" }} disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          Login
                        </button>
                      </div>
                      : <div className="row mx-2 my-4 ms-3">
                        <button className='btn' type='submit' style={{ background: "#005a16", color: "white" }}>Login</button>
                      </div>
                  }
                </form>
                {/* <p className='ms-3'><span className='fw-bold'>Not Registered yet?<Link to="/auth/register" className='text-decoration-none'> Create an Account</Link></span></p> */}

                <p className='ms-3 mt-5' style={{ color: "#9c9c9c" }}>&copy; {year}. All Rights Reserved By <a href="https://wa.me/03066535299" target='blank' style={{ textDecoration: 'none' }}>Zaman Ali</a></p>
              </div>


            </div>
            <div className="col-6" id='carousel' style={{ background: "#005a16", color: "white", borderRadius: "0 9px 9px 0px" }}>
              <div>
                <Carousel autoplay >

                  {/* Slide one */}

                  <div style={contentStyle}>
                    <div className='d-flex justify-content-center'>
                      <img src={require("../../../assests/images/pic1.png")} style={{ height: 450 }} alt="..." />
                    </div>
                    <div className="text-center pb-4" style={{ color: "white" }}>
                      <h5 className='text-white'>Revolutionize Your Business Finances</h5>
                      <p>Unlock CashBook, Ledges, and Sale Invoice Power! </p>
                    </div>
                  </div>

                  {/* Slide two */}

                  <div style={contentStyle}>
                    <div className='d-flex justify-content-center'>
                      <img src={require("../../../assests/images/pic2.png")} style={{ height: 450 }} alt="..." />
                    </div>
                    <div className="text-center" style={{ color: "white" }}>
                      <h5 className='text-white'>Master Accounting</h5>
                      <p>Unlease CashBook, Ledger, and Sale Invoice Mastery!</p>
                    </div>
                  </div>

                  {/* Slide Three */}

                  <div style={contentStyle}>
                    <div className='d-flex justify-content-center'>
                      <img src={require("../../../assests/images/pic3.png")} style={{ height: 450 }} alt="..." />
                    </div>
                    <div className="text-center" style={{ color: "white" }}>
                      <h5 className='text-white'>Supercharge Your Finances</h5>
                      <p>Discover the Magic of Cashbook, Ledger, and Sale Invoices!</p>
                    </div>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default LoginForm
