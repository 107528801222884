import React from 'react';


import "./Home.scss"
import HomeComponents from '../../../components/HomeScreen/HomeComponents'
export default function Home() {

  return (
    <>
      <HomeComponents />
    </>
  )
}
