import React from 'react'

import Accounts from './Accounts'

export default function Index() {
  return (
    <>
      <Accounts />
    </>
  )
}
