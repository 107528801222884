import { message } from 'antd';
import React, { useState } from 'react'

export default function ForgetPassword() {

    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState({ email: "" });

    const handlechange = e => {

        setState(s => ({ ...s, [e.target.name]: e.target.value }))
    }
   
        const handleSubmit=()=>{
            // setIsLoading(true)
            // const { email } = state
            // const auth = getAuth();
            // sendPasswordResetEmail(auth, email,{url:"https://account-system-ff0b9.web.app/"})
            //     .then(() => {
            //        message.success("Email Sending Successfully")
            //        setIsLoading(false)
            //     })
            //     .catch((error) => {
            //       message.error("Error find in sending Email")
            //         // ..
            //     });

        }
    return (
        <div className="container">
            <div className="row d-flex justify-content-center align-items-center vh-100">
                <div className="col-12 col-md-6">
                    <h1 className='text-center mb-5'>Forgot Password</h1>
                <form onSubmit={handleSubmit}>
            <div className="row mx-2 mb-4 ">
                <label className='fw-bold'>Enter Your Email</label>
                <input type="email" className='form-control rounded-3' name='email' placeholder='Sweetzamanali@gamil.com' required onChange={handlechange} />
            </div>


            {
                isLoading ?
                    <div className="row mx-2 my-4 ms-3">
                        <button className="btn btn-success" type="submit" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Sending Link
                        </button>
                    </div>
                    : <div className="row my-4">
                        <div className="col mx-2">
                        <button className='btn btn-success rounded-3 ' type='submit'>Send Link</button>
                        </div>
                    </div>
            }
        </form>
                </div>
            </div>
        </div>
    )
}
