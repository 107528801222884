import React, { useState } from 'react';
import axios from 'axios';
import { message } from 'antd';
import { useAuthcontexts } from '../../../../contexts/Authcontexts';
import { useFetchCustomerContext } from '../../../../contexts/FetchCustomersContext';
import { URL } from '../../../../Constants';


const initialState = { accountNo: 0, firstName: "", lastName: "", typeOfAccount: "", openingBalance: 0, email: "", phoneNumber: 0, district: "", city: "", address: "" }


const accountType = ["", "Admin", "Bank Balance", "Payable/Craditors", "Reaciveable/debitors", "Payable/Salary", "Sale", "Puscheses", "Admin Expense", "Fix Assets"];

const Cities = ["", "66 Tandla", "Ada 5 Chak", "Adda jahan khan", "Aminpur Bangla", "Islamabad", "Ahmed Nager", "Ahmadpur Sharkia", "Ali Khan", "Alipur", "Arifwala", "Attock", "Bhera", "Bhalwal", "Bahawalnagar",
    "Bahawalpur", "Bangla Gogera", "BawaChak", "Bhakkar", "Burewala", "Chillianwala", "Chakwal", "Chichawatni", "Chiniot",
    "Chishtian", "Chowk Munda", "Daska", "Darya Khan", "Dahranwala", "Dairy", "Dera Ghazi", "Dhamthal", "Dhaular", "Dina", "Dinga", "Dipalpur",
    "Faisalabad", "Farooqabad", "Fateh Jhang", "Faqir Wali", "Fort Abbas", "Ghakhar Mandi", "Gojra", "Gujranwala", "Gujrat", "Gujar Khan", "Hafiz wala", "Hafizabad",
    "Haroonabad", "Hasilpur", "Haveli", "Lakha", "Jalalpur", "Jahanian mandi", "Jattan", "Jampur", "Jaranwala", "Jhang", "Jhelum",
    "Kalabagh", "Karor Lal", "Kasur", "Kamalia", "Kamoke", "Khanewal", "Khanpur", "Kharian", "Khushab", "Kot Adu",
    "Jauharabad", "Lahore", "Lalamusa", "Layyah", "Liaquat Pur", "Lalazar", "Lodhran", "Malakwal", "Mamoori", "Mailsi",
    "Mandi Bahauddin", "mian Channu", "Mianwali", "Multan", "Murree", "Muridke", "Mianwali Bangla", "Muzaffargarh",
    "Narowal", "Okara", "Renala Khurd", "Pakpattan", "Pattoki", "Piplan", "Pir Mahal", "Qaimpur", "Qila Didar",
    "Rabwah", "Raiwind", "Rajana", "Rajanpur", "Rahim Yar", "Rawalpindi", "Sadiqabad", "Safdarabad", "Sahiwal", "Sangla Hill",
    "Sarai Alamgir", "Sargodha", "Shakargarh", "Sheikhupura", "Samundri", "Sialkot", "Sohawa", "Soianwala", "Siranwali", "Talagang",
    "Taxila", "Toba Tek", "Vehari", "Wah Cantonment", "Wazirabad", "Badin", "Bhirkan", "Rajo Khanani", "Chak",
    "Dadu", "Digri", "Diplo", "Dokri", "Ghotki", "Haala", "Hyderabad", "Islamkot", "Jacobabad", "Jamshoro",
    "Jungshahi", "Kandhkot", "Kandiaro", "Karachi", "Kashmore", "Keti Bandar", "Khairpur", "Kot Radha Kishan", "Kotri", "Larkana",
    "Matiari", "Mehar", "Mirpur Khas", "Mithani", "Mithi", "Mehrabpur", "Mandi yazman", "Moro", "Nagarparkar", "Naudero",
    "Naushahro Feroze", "Naushara", "Nawabshah", "Nazimabad", "Patizan", "Pasroor", "Qambar", "Qasimabad", "Ranipur", "Ratodero",
    "Rohri", "Sadhar", "Sakrand", "Sanghar", "Shahbandar", "Shahdadkot", "Shahdadpur", "Shahpur", "Shah Koot", "Shikarpaur",
    "Sukkur", "Tangwani", "Tando Adam", "Tando Allahyar", "Tando Muhammad", "Thatta", "Umerkot", "Warah",
    "Abbottabad", "Adezai", "Alpuri", "Akora Khattak", "Ayubia", "Banda Daud", "Bannu", "Batkhela", "Battagram",
    "Birote", "Chakdara", "Charsadda", "Chitral", "Daggar", "Dargai", "Darya Khan", "dera Ismail", "Doaba",
    "Dir", "Drosh", "Hangu", "Haripur", "Karak", "Kohat", "Kulachi", "Lakki Marwat", "Latamber", "Madyan",
    "Mansehra", "Mardan", "Mastuj", "Mingora", "Nowshera", "Paharpur", "Pabbi", "Peshawar", "Saidu Sharif",
    "Shorkot", "Shewa Adda", "Swabi", "Swat", "Tangi", "Tank", "Thall", "Tulamba", "Timergara", "Tordher", "Awaran",
    "Barkhan", "Chagai", "Dera Bugti", "Gwadar", "Harnai", "Jafarabad", "Jhal Magsi", "Kacchi", "Kalat", "Kech",
    "Kharan", "Khuzdar", "Killa Abdullah", "Killa Saifullah", "Kohlu", "Lasbela", "Lehri", "Loralai", "Mastung",
    "Musakhel", "Nasirabad", "Nushki", "Panjgur", "Pishin valley", "Quetta", "Sherani", "Sibi", "Sohbatpur",
    "Washuk", "Zhob", "Ziarat"];

const districts = ['', 'Attock', 'Bahawalnagar', 'Bahawalpur', 'Bhakkar', 'Chakwal', 'Chiniot', 'Faisalabad',
    'Gujranwala', 'Gujrat', 'Hafizabad', 'Jhelum', 'Khnewal', 'Khushab', 'Layyah', 'Lodhran', 'Mandi Bahauddin',
    'Mianwali', 'Multan', 'Muzaffargarh', 'Nankana Sahib', 'Okara', 'Pakpattan', 'Rahim Yar Khan', 'Rajanpur', 'Rawalpindi', 'Sahiwal', 'Srgodha', 'Sheikhupura', 'Toba Tek singh',
    'Vehari'];

export default function Add() {

    const [state, setState] = useState(initialState);
    const [isloading, setIsLoding] = useState(false);

    // fetch Data of user

    const { user } = useAuthcontexts();

    // fetch Data of Cutomers

    const { customers, setCustomers } = useFetchCustomerContext();

    const handleChange = e => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    let lastAccountNo = 0
    if (!customers.length) {
        lastAccountNo = 0
    }
    else {
        const maxVoucherNo = Math.max(...customers.map((entry) => entry.accountNo));
        lastAccountNo = maxVoucherNo;
    }

    const handleSubmit = async (e) => {

        e.preventDefault()


        let { accountNo, firstName, lastName, typeOfAccount, openingBalance, email, phoneNumber, district, city, address } = state

        firstName = firstName.trim();
        lastName = lastName.trim();
        email = email.trim();
        address = address.trim();
        openingBalance = Number(openingBalance);
        accountNo = lastAccountNo + 1;

        if (firstName.length < 3) {

            return message.error("Please Enter Your FirstName Correctly")
        }

        let customerName = firstName + " " + lastName;
        customerName = customerName.trim()

        if (customerName) {
            const validName = customers.find(customer => customer.fullName.toLowerCase() === customerName.toLocaleLowerCase())
            if (validName) {
                return message.error("Customer name already existed please choose an other name to ADD")
            }

        }
        if (!user) {
            return message.error("Please Login First")
        }

        // if (phoneNumber.length !== 11) {
        //     return message.error("Make sure that your Cell Number must be 11 Digits")
        // }
        // if (!city) {
        //     return message.error("please Enter Your City Correctlly")
        // }
        // if (address.length < 10) {
        //     return message.error("Please Enter Your Proper Address")
        // }


        setIsLoding(true)

        const data = {
            accountNo,
            firstName,
            lastName,
            fullName: customerName,
            typeOfAccount,
            openingBalance,
            email,
            phoneNumber,
            district,
            city,
            address,
            id: Math.random().toString(36).slice(2),
            dateOfCreated: new Date().toISOString().slice(0, 10),
            shopId: user.shopId,
            createdByUserId: user.id,
            createdByUserEmail: user.email,
            createdByUserName: user.fullName,
        }
        axios.post(`${URL}/customer/add`, data)
            .then((res) => {
                message.success("Customer Added Successfully")
                setCustomers([...customers, data])
                setState(initialState)
            })
            .catch((error) => {
                if (error.response.status)
                    message.error(error.response.data.error)
                else
                    message.error(error.message)
            })

        setIsLoding(false)
    }
    return (
        <>


            <div className="container-fluid mb-1 mb-md-3">
                <div className="row d-flex justify-content-center">
                    <div className="col-12 px-0">
                        <div style={{ borderRadius: '1rem' }}>
                            <div className="card-body p-0 p-md-3 mt-md-5 bg-white">

                                <div className="mb-2" >


                                    <form onSubmit={handleSubmit}>
                                        <div className="row mx-2 mb-md-3 pt-2">
                                            <div className="col-12 mb-2 col-md-4">
                                                <label htmlFor="" style={{ color: "#156829" }}>ID  <span className='text-danger'>*</span></label>
                                                <input type="number" placeholder='0000' className='form-control form-control-sm ' name='accountNo' value={lastAccountNo + 1} onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mb-2 col-md-4">
                                                <label htmlFor="" style={{ color: "#156829" }}>First Name <span className='text-danger'>*</span></label>
                                                <input type="text" placeholder='Zaman' name='firstName' className='form-control form-control-sm ' value={state.firstName} required onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mb-2 col-md-4">
                                                <label htmlFor="" style={{ color: "#156829" }}>Last Name</label>
                                                <input type="text" placeholder='Ali' name='lastName' className='form-control form-control-sm ' value={state.lastName} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row mx-2 mb-md-3">
                                            <div className="col-12 mb-2 col-md-6">
                                                <label htmlFor="" style={{ color: "#156829" }}>Type of Accounts <span className='text-danger'>*</span></label>
                                                <br />
                                                <select name="typeOfAccount" id="" className='form-control form-control-sm' value={state.typeOfAccount} onChange={handleChange} required>
                                                    {
                                                        accountType.map((acc, i) => {
                                                            return (
                                                                <option key={i} className=''>{acc}</option>

                                                            )
                                                        })
                                                    }
                                                </select>
                                                {/* <input type="text" placeholder='Creditors/Debitors' name='typeOfAccount' className='form-control form-control-sm ' required onChange={handleChange}/> */}
                                            </div>
                                            <div className="col-12 mb-2 col-md-6">
                                                <label htmlFor="" style={{ color: "#156829" }}>Opening Balance (+ debit ,-Credit) <span className='text-danger'>*</span></label>
                                                <input type="number" placeholder='' name='openingBalance' className='form-control form-control-sm ' value={state.openingBalance} required onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row mx-2 mb-md-3">
                                            <div className="col-12 mb-2 col-md-6">
                                                <label htmlFor="" style={{ color: "#156829" }}>Email</label>
                                                <input type="email" placeholder='Sweetzamanali@gmail.com' name='email' className='form-control form-control-sm ' value={state.email} onChange={handleChange} />
                                            </div>
                                            <div className="col-12 mb-2 col-md-6">
                                                <label htmlFor="" style={{ color: "#156829" }}>Phone Number</label>
                                                <input type="number" placeholder='+92 306 6525299' name='phoneNumber' className='form-control form-control-sm ' value={state.phoneNumber} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row mx-2 mb-md-3">
                                            <div className="col-12 col-md-6 mb-2">
                                                <label htmlFor="" style={{ color: "#156829" }}>District  <span className='text-danger'>*</span></label>
                                                <br />
                                                <select name="district" id="" className='form-control form-control-sm ' value={state.district} onChange={handleChange}>
                                                    {
                                                        districts.map((acc, i) => {
                                                            return (
                                                                <option key={i} className=''>{acc}</option>

                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 mb-2">
                                                <label htmlFor="" style={{ color: "#156829" }}>City <span className='text-danger'>*</span></label>
                                                <br />
                                                <select name="city" id="" className='form-control form-control-sm ' value={state.city} onChange={handleChange}>
                                                    {
                                                        Cities.map((city, i) => {
                                                            return (
                                                                <option key={i} className=''>{city}</option>

                                                            )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                        </div>

                                        <div className="row mx-2 mb-4">
                                            <div className="col-12 mb-2">
                                                <label htmlFor="" style={{ color: "#156829" }}>Address <span className='text-danger'>*</span></label>
                                                <input type="text" placeholder='East Cannal Road Faisalabad' name='address' className='form-control form-control-sm ' value={state.address} onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="row mx-2">
                                            {
                                                !isloading ?
                                                    <div className="col text-end">
                                                        <button className='btn btn-white border-success mb-3' type='submit'><span className='ms-2' style={{ color: "#156829" }}>Add Account</span> </button>
                                                    </div>
                                                    : <div className="col text-end">
                                                        <button className="btn btn-success border-success mb-3" type="button" disabled>
                                                            <span className="spinner-border text-success spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Loading...
                                                        </button>

                                                    </div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}