import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';


import Dashboard from "./Dashboard";
import Authentication from "./Authentication";

import { useAuthcontexts } from '../contexts/Authcontexts';
import PrivateRoute from '../components/PrivateRoute';




export default function Index() {

  const { isAuthenticated } = useAuthcontexts();

  return (


    <Routes>
      {/* <Route path='/*' element={<Dashboard />} />
      <Route path='/auth/*' element={<Authentication />} /> */}
      <Route path='/*' element={<PrivateRoute Component={Dashboard} />} />
      <Route path='/auth/*' element={!isAuthenticated ? <Authentication /> : <Navigate to="/" />} />
    </Routes>




  )
}
