import React, { useState, useRef, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useFetchCustomerContext } from '../../../contexts/FetchCustomersContext';
import { useFetchCashBook } from '../../../contexts/FetchCashBook';

import './AllAccounts.scss';
import { useAuthcontexts } from '../../../contexts/Authcontexts';
import { AccountSection, calculateBalances } from '../../../components/GlobalFunctions';

export default function AllAccounts() {

  const [state, setState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isloading, setIsLoding] = useState(false);
  const componenRef = useRef();

  // fetch Data of Cutomers

  const { user } = useAuthcontexts();
  const { customers } = useFetchCustomerContext();
  const { getCashBook } = useFetchCashBook();


  useEffect(() => {
    const calculatedBalances = calculateBalances(customers, getCashBook);
    setState(calculatedBalances)
  }, [])


  let totalCredit = 0;
  let totalDebit = 0;
  for (const key in getCashBook) {
    if (getCashBook.hasOwnProperty(key)) {
      const creditValue = getCashBook[key].credit;
      const debitValue = getCashBook[key].debit;
      totalCredit += creditValue;
      totalDebit += debitValue;
    }
  }

  let openingBalance = 0;
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      const TotalOpeningBalance = state[key].customerOpeningBalance;
      openingBalance += TotalOpeningBalance;
    }
  }

  let cashInHand = totalCredit - totalDebit + openingBalance

  const filterDebit = state.filter((customer) => customer.balance < 0)
  const filterCredit = state.filter((customer) => customer.balance > 0)

  const filterOpeningBalance = state.filter((customer) => customer.openingBalance < 0 || customer.openingBalance > 0)

  const admin = state.filter((customer) => customer.customerType === 'Admin')
  const filterAdmin = admin.filter((customer) => customer.balance !== 0)

  const banks = state.filter((customer) => customer.customerType === 'Bank Balance')
  const filterBanks = banks.filter((customer) => customer.customerType !== 0)

  const payAbles = state.filter((customer) => customer.customerType === 'Payable/Craditors')
  const filterPayables = payAbles.filter((customer) => customer.balance !== 0)

  const receiveAbles = state.filter((customer) => customer.customerType === 'Reaciveable/debitors')
  const filterReceiveAbles = receiveAbles.filter((customer) => customer.balance !== 0)

  const salary = state.filter((customer) => customer.customerType === 'Payable/Salary')
  const filterSalary = salary.filter((customer) => customer.balance !== 0)

  const sale = state.filter((customer) => customer.customerType === 'Sale')
  const filterSale = sale.filter((customer) => customer.balance !== 0)

  const puscheses = state.filter((customer) => customer.customerType === 'Puscheses')
  const filterPuscheses = puscheses.filter((customer) => customer.balance !== 0)

  const adminExpense = state.filter((customer) => customer.customerType === 'Admin Expense')
  const filterAdminExpense = adminExpense.filter((customer) => customer.balance !== 0)

  const fixAssets = state.filter((customer) => customer.customerType === 'Fix Assets')
  const filterFixAssets = fixAssets.filter((customer) => customer.balance !== 0)



  let sumfilteredDebit = 0;
  for (const key in filterDebit) {
    if (filterDebit.hasOwnProperty(key)) {
      const creditValue = filterDebit[key].balance;
      sumfilteredDebit += creditValue;
    }
  }
  let sumfilteredCredit = 0;
  for (const key in filterCredit) {
    if (filterCredit.hasOwnProperty(key)) {
      const creditValue = filterCredit[key].balance;
      sumfilteredCredit += creditValue;
    }
  }

  let sumfilteredCredit2 = 0;
  for (const key in filterOpeningBalance) {
    if (filterOpeningBalance.hasOwnProperty(key)) {
      const creditValue = filterOpeningBalance[key].openingBalance;
      sumfilteredCredit2 += creditValue;
    }
  }

  if (sumfilteredCredit2 < 0) {
    sumfilteredCredit = sumfilteredCredit - sumfilteredCredit2
  }
  else {
    sumfilteredDebit = sumfilteredDebit - sumfilteredCredit2
  }

  // set Current date and time 

  const date = new Date();

  const currentDate = date.toDateString();
  const currentTime = (date.toLocaleTimeString());


  const handlePrint = useReactToPrint({
    content: () => componenRef.current,
    documentTitle: "Trial Balance"
  })

  const combinedData = [
    { type: 'Admin', accounts: filterAdmin },
    { type: 'Bank Balance', accounts: filterBanks },
    { type: 'Payable/Craditors', accounts: filterPayables },
    { type: 'Reaciveable/debitors', accounts: filterReceiveAbles },
    { type: 'Payable/Salary', accounts: filterSalary },
    { type: 'Sale', accounts: filterSale },
    { type: 'Puscheses', accounts: filterPuscheses },
    { type: 'Admin Expense', accounts: filterAdminExpense },
    { type: 'Fix Assets', accounts: filterFixAssets },

  ];

  return (
    <>
      <div className='container'>
        <div className="row m-0 m-md-1 bg-white rounded vh-100 overflow-scroll abc">
          <div className="col-12 mt-2">
            <div ref={componenRef} className='afterPrint'>

              {
                isloading ?
                  <div className="d-flex justify-content-center align-items-center min-vh-100">
                    <div className="spinner-border text-success" role="status" style={{ height: '3rem', width: "3rem" }}>
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                  :
                  <div>
                    <div className='row'>
                      <div className="col-5 d-none d-md-block abcd" style={{ color: "#0c6321" }}>
                        <h4 className='text-capitalize'>{user.companyName ? user.companyName : 'Zaman Ali'}</h4>
                      </div>
                      <div className="col text-end mt-3 mt-md-2"><div className='fw-bold' style={{ color: "#0c6321" }}>{currentDate} <span className='d-none d-md-inline-block abcd'>{currentTime}</span></div>          </div>
                    </div>
                    <div className='row my-2'>
                      <div className="col text-center" style={{ color: "#0c6321" }}><h4>Trail Balance Sheet</h4></div>
                    </div>

                    <div className="row mb-3">
                      <AccountSection title="Accounts" data={combinedData} />
                    </div>
                    <div className="row">
                      <div className="col fw-bold ms-2">
                        <div className="row">
                          <div className="col">
                            Total Debit
                          </div>
                          <div className="col">
                            {(-sumfilteredDebit)?.toLocaleString()}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            Cash In Hand
                          </div>
                          <div className="col">
                            {cashInHand?.toLocaleString()}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col">
                            Total
                          </div>
                          <div className="col  text-danger">
                            {((-sumfilteredDebit) + cashInHand)?.toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="col fw-bold ms-3">
                        <div className="row">
                          <div className="col">
                            Total Credit
                          </div>
                          <div className="col  text-danger">
                            {sumfilteredCredit?.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
            <div className='row d-flex justify-content-end'>
              <div className="col-5 col-md-2 text-center mb-2"> <button className='btn fw-bold w-100 border rounded-3' style={{ color: "#0c6321", background: '#b6e2c6' }} onClick={handlePrint}>Print </button></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
