import React from 'react'
import Ledger from './Ledger'

export default function Index() {
  return (
    <>
      <Ledger />
    </>
  )
}
