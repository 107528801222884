import React, { useState } from 'react'
import { useFetchCustomerContext } from '../../../../contexts/FetchCustomersContext';

export default function FilterAccounts() {

    const [filterOptions, setFilterOptions] = useState({ option1: false, option2: false, option3: false });
    const [state, setState] = useState([])
    const [currentPage, setCurrentPage] = useState(1)

    // fetch Data of Cutomers
    const { customers } = useFetchCustomerContext();

    const handleChange = e => {
        setFilterOptions({ ...filterOptions, [e.target.name]: e.target.checked });

    }

    const abc = () => {
        const { option1, option2, option3 } = filterOptions
        if (option1 && option2 && option3) {
            const reacieve = customers.filter(customer => customer.typeOfAccount === "Reaciveable/debitors")
            const payable = customers.filter(customer => customer.typeOfAccount === "Payable/Craditors")
            const salary = customers.filter(customer => customer.typeOfAccount === "Salary")
            let allcustomers = [...reacieve, ...payable, ...salary]
            setState(allcustomers)
        }
        else if (option1 && option2) {
            const payable = customers.filter(customer => customer.typeOfAccount === "Payable/Craditors")
            const reacieve = customers.filter(customer => customer.typeOfAccount === "Reaciveable/debitors")
            let allcustomers = [...reacieve, ...payable]
            setState(allcustomers)
        }
        else if (option2 && option3) {
            const reacieve = customers.filter(customer => customer.typeOfAccount === "Reaciveable/debitors")
            const payable = customers.filter(customer => customer.typeOfAccount === "Salary")
            let allcustomers = [...reacieve, ...payable]
            setState(allcustomers)
        }
        else if (option3 && option1) {
            const reacieve = customers.filter(customer => customer.typeOfAccount === "Salary")
            const payable = customers.filter(customer => customer.typeOfAccount === "Payable/Craditors")
            let allcustomers = [...reacieve, ...payable]
            setState(allcustomers)
        }
        else if (option1) {
            const payable = customers.filter(customer => customer.typeOfAccount === "Payable/Craditors")
            setState(payable)

        }
        else if (option2) {
            const payable = customers.filter(customer => customer.typeOfAccount === "Reaciveable/debitors")
            setState(payable)

        }
        else if (option3) {
            const payable = customers.filter(customer => customer.typeOfAccount === "Payable/Salary")
            setState(payable)

        }
        else {
            setState([])
        }

    }
    const sortedCustomers = [...state].sort((a, b) => a.accountNo - (b.accountNo));

    const rowsPerPage = 15;
    const lastIndex = currentPage * rowsPerPage;
    const firstIndex = lastIndex - rowsPerPage;
    const data = sortedCustomers.slice(firstIndex, lastIndex);
    const npages = Math.ceil(sortedCustomers.length / rowsPerPage);
    const number = [...Array(npages + 1).keys()].slice(1)

    const handleChangePage = (id) => {
        setCurrentPage(id)
    }
    const handlePre = () => {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    const handleNext = () => {
        if (currentPage !== npages) {
            setCurrentPage(currentPage + 1)
        }
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row py-2">
                            <div className="col">
                                <button className='btn btn-success rounded' data-bs-toggle="modal" data-bs-target="#exampleModal">Filter By</button>
                            </div>
                        </div>
                        <hr />
                        <div className="row mt-4">
                            <div className="col-12 mb-3">
                                <div className="table-responsive rounded">
                                    <table className="table table-sm">
                                        <thead>
                                            <tr className='' style={{ background: "#c1e6c3", color: "#005a16" }}>
                                                <th>Sr.No</th>
                                                <th>AC#</th>
                                                <th>AC Name</th>
                                                <th>AC Type</th>
                                                <th>City</th>
                                                <th>Cell NO#</th>
                                                <th>Created By</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.map((document, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <th scope="row"> {i + 1} </th>
                                                            <td>{document.accountNo}</td>
                                                            <td style={{ textTransform: 'capitalize' }}>{document.fullName}</td>
                                                            <td>{document.typeOfAccount}</td>
                                                            <td>{document.city}</td>
                                                            <td>{document.phoneNumber}</td>
                                                            <td>{document.createdByUserName}</td>
                                                        </tr>
                                                    )
                                                })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    data.length ?
                                        <nav>
                                            <ul className="pagination pagination-sm justify-content-end">
                                                <li className="page-item">
                                                    <button className="page-link" aria-label="Previous" onClick={handlePre}>
                                                        Prev
                                                    </button>
                                                </li>
                                                {
                                                    number.map((n, i) => (
                                                        <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                            <button className="page-link" onClick={() => handleChangePage(n)}>{n}</button>
                                                        </li>
                                                    ))
                                                }
                                                <li className="page-item">
                                                    <button className="page-link" aria-label="Next" onClick={handleNext}>
                                                        Next
                                                    </button>
                                                </li>
                                            </ul>
                                        </nav>
                                        : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalLabel">Filter By</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault1" name="option1" checked={filterOptions.option1} onChange={handleChange} />
                                <label className="form-check-label" for="flexCheckDefault1">
                                    Payable/Craditors
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault2" name="option2" checked={filterOptions.option2} onChange={handleChange} />
                                <label className="form-check-label" for="flexCheckDefault2">
                                    Reaciveable/debitors
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" id="flexCheckDefault3" name="option3" checked={filterOptions.option3} onChange={handleChange} />
                                <label className="form-check-label" for="flexCheckDefault3">
                                    Salary
                                </label>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary rounded" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" className="btn btn-success rounded" data-bs-dismiss="modal" onClick={abc}>Apply</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
